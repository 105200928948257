import { capitalize, sortBy } from 'lodash';
import React from 'react';

import type { Occupation } from 'models';

import {
  dateWithoutTimeFromDateString,
  formatDateToLocaleMonthAndYear,
} from 'helpers/date';
import { __ } from 'helpers/i18n';

import { Hoverable, Text } from 'components';

import SalaryInfo from './SalaryInfo';

type Props = {
  occupations: Array<Occupation>;
  blurSalary: boolean;
  actionsForOccupation?: (occupation: Occupation) => React.ReactNode;
  inUserPanel?: boolean;
};

const hasSalaryInfo = (occupation: Occupation) =>
  !!occupation.fixedRemunerationCents ||
  !!occupation.variableRemunerationCents ||
  !!occupation.remunerations?.length;

type SalaryItemProps = {
  label: string;
  amount: number | null | undefined;
  currency: string | null | undefined;
  percentChange: number | null | undefined;
  blurred: boolean;
  inUserPanel: boolean | undefined;
};

const SalaryItem = ({
  label,
  amount,
  currency,
  percentChange,
  blurred,
  inUserPanel,
}: SalaryItemProps) => {
  if (!amount || !currency) {
    return null;
  } else {
    return (
      <SalaryInfo
        label={label}
        amount={amount}
        currency={currency}
        percentChange={percentChange}
        blurred={blurred}
        inUserPanel={inUserPanel}
      />
    );
  }
};

const SalaryHistory = ({
  occupations,
  blurSalary,
  actionsForOccupation,
  inUserPanel,
}: Props) => {
  return (
    <ul>
      {occupations.map(occupation => {
        if (!hasSalaryInfo(occupation)) {
          return null;
        }
        const humanizedStartDate = capitalize(
          formatDateToLocaleMonthAndYear(
            dateWithoutTimeFromDateString(occupation.startDate)
          )
        );

        return (
          <li
            key={occupation.id}
            className="my-1 p-3 bg-ui-highlight-gray rounded-lg"
          >
            <Hoverable
              render={isHovered => (
                <div className="flex justify-between items-center">
                  <div>
                    <Text preset="14bs6" color="soften" className="block mb-0">
                      {humanizedStartDate}
                    </Text>
                    <SalaryItem
                      label={
                        inUserPanel
                          ? __('Fixed')
                          : __('Fixed annual remuneration')
                      }
                      amount={occupation.fixedRemunerationCents}
                      currency={occupation.fixedRemunerationCurrency}
                      percentChange={occupation.fixedRemunerationPercentChange}
                      blurred={blurSalary}
                      inUserPanel={inUserPanel}
                    />
                    <SalaryItem
                      label={
                        inUserPanel
                          ? __('Variable')
                          : __('Variable annual remuneration')
                      }
                      amount={occupation.variableRemunerationCents}
                      currency={occupation.variableRemunerationCurrency}
                      percentChange={
                        occupation.variableRemunerationPercentChange
                      }
                      blurred={blurSalary}
                      inUserPanel={inUserPanel}
                    />
                    {sortBy(occupation.remunerations || [], 'position').map(
                      remuneration => (
                        <SalaryItem
                          label={remuneration.remunerationTypeName}
                          amount={remuneration.valueCents}
                          currency={remuneration.valueCurrency}
                          percentChange={remuneration.percentChange}
                          blurred={blurSalary}
                          inUserPanel={inUserPanel}
                        />
                      )
                    )}
                  </div>
                  <div>
                    {isHovered &&
                      !!actionsForOccupation &&
                      actionsForOccupation(occupation)}
                  </div>
                </div>
              )}
            />
          </li>
        );
      })}
    </ul>
  );
};
export default SalaryHistory;
